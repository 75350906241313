import React from 'react';
import { Link } from 'gatsby';
import Img from 'gatsby-image';
import styles from './pay-overview-section.module.scss';

export default ({ fluid }) => {
  return (
    <div className="columns">
      <div
        className={`columns column is-12 is-10-desktop is-offset-1-desktop has-text-centered-mobile`}
      >
        <div className="column is-6">
          <h3 className="title is-5 has-text-info">Your pay—you’re in control</h3>
          <div className="content">
            <p className="content">
              Whether you have your heart set on a specific location or you're ready to go where the wind (and paycheck) 
              takes you, one of the best parts of traveling as an allied health professional is the flexibility to choose 
              jobs that suit you best.
            </p>
            <p className="content">
              Your recruiter will negotiate the best compensation package possible, while still 
              ensuring you have the amenities you need. Whatever your priorities, we offer flexible 
              solutions to ensure you're satisfied with your paycheck.
            </p>
            <p className="content">
            Start by searching our <Link to="/jobs/">highest-paying jobs</Link> or speak to a recruiter today.
            </p>
          </div>
          <Link to="/jobs/" className="button is-primary">
            Search jobs
          </Link>
        </div>
        <div className={`column is-5 is-offset-1 is-hidden-mobile`}>
          <Img
            fluid={fluid}
            className={styles.aboutImageWrapper}
            alt="high-paying allied travel jobs"
          />
        </div>
      </div>
    </div>
  );
};
