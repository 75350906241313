import React from 'react';
import { Link } from 'gatsby';
import styles from './pay-bonuses-section.module.scss';

export default ({ fluid }) => {
  return (
    <div className="columns">
      <div
        className={`columns column is-12 is-10-desktop is-offset-1-desktop has-text-centered-mobile`}
      >
        <div className="column is-6">
          <h3 className="title is-5 has-text-info">Earn great bonuses</h3>
          <div className="content">
            <p className={`content`}>
              Not only are our allied travelers well paid, you can also boost
              your salary with a variety of bonuses. The type, amount, and
              availability of each bonus depends on your current assignment, so
              be sure to ask your recruiter when you’re selecting your next
              location!
            </p>
            <p className="content">
              If you have a friend, coworker or a family member who wants to
              learn more about becoming a Trustaff traveler, send them our way.
              We love when you recommend Trustaff, and we'll pay a{' '}
              <Link to="/referrals/">
                <b>referral bonus</b>
              </Link>{' '}
              for your effort.
            </p>
            <p className="content">
              You don't even have to be a current Trustaff employee—and there's
              no limit to the number of referral bonuses you can collect!
            </p>
          </div>
          <Link to="/referrals/" className="button is-info">
            Refer a friend
          </Link>
        </div>
        <div
          className={`column is-5 is-offset-1 ${styles.noSidePaddingMobile} ${styles.testimonial}`}
        >
          <p className={`content ${styles.testimonialtext}`}>
            “I hit the gold mine when I got hooked up with my Trustaff
            recruiter! She took all my considerations into account and found me
            assignments that I was thrilled to take.
            <br />
            <br />
            She is always prompt in responding to me and never felt for a second
            like she didn't have my best interest in mind.”
          </p>
          <p className="content">— Holly</p>
        </div>
      </div>
    </div>
  );
};
