import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../../components/layout';
import styles from './pay-bonuses.module.scss';
import HeroImage from '../../components/hero-image/hero-image';
import HeroImageMini from '../../components/hero-image/hero-image-mini';
import { GatsbyImg } from '../../services/img-location/img-location-service';
import PayOverviewSection from '../../components/allied-health/pay/pay-overview-section';
import PayBonusesSection from '../../components/allied-health/pay/pay-bonuses-section';
import PayTaxSection from '../../components/allied-health/pay/pay-tax-section';
import { Helmet } from 'react-helmet';

export default ({ data }) => {
  const getAlliedPayImageEdge = name => {
    return data.allFile.edges.find(e => e.node.name === name);
  };
  const gastbyOGImg = new GatsbyImg('allied-pay-og', data);
  const gatsbyHeroImg = new GatsbyImg('allied-pay', data);

  return (
    <>
      <Helmet>
        <title>Allied traveler pay and bonuses - Trustaff</title>
        <meta charSet="utf-8" />
        <meta
          name="Description"
          content="Allied travelers who travel with Trustaff are some of the best compensated healthcare professionals in the industry. Earn as much as $2,800 a week depending on your specialty &amp; experience!"
        />
        <meta property="og:title" content="Allied traveler pay and bonuses" />
        <meta
          property="og:description"
          content="Allied travelers who travel with Trustaff are some of the best compensated healthcare professionals in the industry. Earn as much as $2,800 a week depending on your specialty &amp; experience!"
        />
        <meta property="og:image" content={gastbyOGImg.ogImgUrl} />
        <meta
          property="og:url"
          content="https://www.trustaff.com/allied-health/pay-bonuses/"
        />
        <meta property="og:locale" content="en_US" />
        <meta property="og:site_name" content="Trustaff" />
        <meta property="og:type" content="website" />
        <meta name="twitter:site" content="@Trustaff" />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>
      <Layout>
        <HeroImage fluid={gatsbyHeroImg.fluidImg}>
          <div className={'columns'}>
            <div className="column is-7 is-offset-1">
              <div className={styles.heroTextArea}>
                <h1 className={`title is-2 has-text-white`}>
                  Allied traveler pay, salary, &amp; bonuses
                </h1>
                <p className={`content`}>
                  Trustaff's allied travelers are some of the best compensated
                  in the industry. When you take your skills and experience on
                  the road, not only do you get to expand your knowledge and see
                  the country, you can earn as much as <b>$2,800 a week!</b>
                </p>
                <p className={`content`}>
                  Allied travelers and other types of healthcare professionals,
                  like traveling therapists, typically earn far more than a
                  staff position. Your individual salary or pay will vary by
                  your modality, certifications, and experience, as well as the
                  location where you take your assignment.
                </p>
              </div>
            </div>
          </div>
        </HeroImage>
        <div className={`section ${styles.sectionNoSidePaddingMobile}`}>
          <PayOverviewSection
            fluid={
              getAlliedPayImageEdge('allied-pay-sidebar-1').node.childImageSharp
                .fluid
            }
          />
        </div>
        <div
          className={`section ${styles.sectionNoSidePaddingMobile} ${styles.sectionBackgroundGrey}`}
        >
          <PayBonusesSection />
        </div>
        <div className={`section ${styles.sectionNoSidePaddingMobile}`}>
          <PayTaxSection
            fluidTop={
              getAlliedPayImageEdge('allied-pay-sidebar-2').node.childImageSharp
                .fluid
            }
            fluidBottom={
              getAlliedPayImageEdge('allied-pay-sidebar-3').node.childImageSharp
                .fluid
            }
          />
        </div>
        <HeroImageMini
          fluid={getAlliedPayImageEdge('big-island').node.childImageSharp.fluid}
        />
      </Layout>
    </>
  );
};

export const pageQuery = graphql`
  {
    allFile(filter: { relativePath: { regex: "/^allied-pay/" } }) {
      edges {
        node {
          name
          childImageSharp {
            fluid(maxWidth: 2400) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`;
