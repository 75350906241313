import React from 'react';
import Img from 'gatsby-image';
import { Link } from 'gatsby';
import styles from './pay-tax-section.module.scss';

export default ({ fluidTop, fluidBottom }) => {
  return (
    <div className="columns">
      <div
        className={`columns column is-12 is-10-desktop is-offset-1-desktop has-text-centered-mobile`}
      >
        <div className="column is-6">
          <h3 className="title is-5 has-text-info">
            Make the most of your allied salary
          </h3>
          <div className="content">
            <p className={`content`}>
              When you work for Trustaff as an{' '}
              <Link to="/allied-health/how-it-works/">allied health traveler</Link>, you make
              your living away from the comforts of home. You have two
              residences to maintain when you travel: your home-away-from-home,
              where you rest your head after a long shift, and the home where
              you reside when you're not on the road. The Internal Revenue
              Service calls your ‘home’ your permanent tax residence.
            </p>
            <p className={'content'}>
              It can be very costly to maintain two homes while traveling; the
              IRS recognized this dilemma and created a special plan to assist
              traveling professionals. People who work away from their tax homes
              are allowed under special IRS-provided rules to receive
              reimbursements—in specific amounts that do not exceed government
              rates—without paying taxes on those reimbursements.
            </p>
            <p className={`content ${styles.subheader}`}>
              The Expense Reimbursement Plan
            </p>
            <p className="content">
              You can be paid tax-free rates if you qualify under the rules
              outlined by the IRS, which we call our expense reimbursement plan.
              If you qualify, you are eligible for an overall increase to your
              weekly "take home" pay. Some restrictions do apply, so be sure to
              talk to your recruiter for full details about how the expense
              reimbursement plan works.
            </p>
            <hr />
            <p className="content">
              Learn about Trustaff's other{' '}
              <Link to="/allied-health/benefits/">
                allied traveler benefits
              </Link>
              , including travel and housing assistance,{' '}
              <Link to="/perks-discounts/">
                employee discounts
              </Link>
              , and affordable health insurance options that start at just{' '}
              <b>$23/week!</b>
            </p>
          </div>
        </div>
        <div
          className={`column is-5 is-offset-1 ${styles.noSidePaddingMobile}`}
        >
          <Img
            fluid={fluidTop}
            className={styles.aboutImageWrapper}
            alt="allied traveler bonuses"
          />
          <Img
            fluid={fluidBottom}
            className={`is-hidden-mobile ${styles.aboutImageWrapper} ${styles.img2}`}
            alt="allied traveler pay"
          />
        </div>
      </div>
    </div>
  );
};
